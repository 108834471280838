import Type from './action-type';
import api, { GetAppUserMeRes } from '../api';
import { ActionConfigRes, MyThunkDispatch, setConfigData, setUserToken } from '.';
import { PutAppUserMeParameters } from '../api/api-parameters-type';
import { toolGetState } from '../tool';
import { ActionMetadataRes } from './action-metadata';

const updataActon = (data: ActionUserRes) => {
    return {
        type: Type.userType.UP_DATA, ...data
    }
}

const initDataAction = (): any => {
    return {
        type: Type.userType.INIT_DATA
    }
}




export type ActionUserRes = GetAppUserMeRes

/**
 * 获取用户 并设置全局语言
 */
export const getAppUserMe = () => {
    return async (dispatch: MyThunkDispatch) => {

        const res = await api.GetAppUserMeV4()

        if (res.kind !== 'ok') {
            throw new Error(res.kind)
        }
        const configRes = <ActionConfigRes>toolGetState().config
        //设置国际化
        configRes.language = res.data.language
        dispatch(setUserToken(true))
        dispatch(setConfigData(configRes))
        dispatch(updataActon(res.data))
        return res.data
    }
}

export const putAppUserMe = (params: PutAppUserMeParameters) => {
    return async (dispatch: MyThunkDispatch) => {
        const res = await api.PutAppUserMe(params)
        if (res.kind !== 'ok') {
            throw new Error(res.kind)
        }
        return res.data
    }
}

export const initData = () => {
    return async (dispatch: MyThunkDispatch) => {
        dispatch(initDataAction())
    }
}













