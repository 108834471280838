import { I18n, setLocale } from "react-redux-i18n"
import { useLoaderData, useNavigation, Outlet, Navigate, useParams, useAsyncValue, redirect, useNavigate, useSearchParams } from "react-router-dom"
import { getMetadata } from "../../actions/action-metadata"
import { getAppUserMe, putAppUserMe } from "../../actions/action-user"
import api, { GetAppUserMeRes } from "../../api"
import { PostAppAuthFamilyIdLoginParameters } from "../../api/api-parameters-type"
import { Nav, PageBox } from "../../components"
import { useAppDispatch } from "../../store/hooks.ts"


const postAppAuthFamilyIdLogin = async (params: PostAppAuthFamilyIdLoginParameters) => {
    const res = await api.PostAppAuthFamilyIdLogin(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
}


export const Root = () => {
    const { rootParams } = useParams() as {
        rootParams?: string
    }
    const [searchParams, setSearchParams] = useSearchParams();
    console.log('pathname', window.location.search)
    const familyId = searchParams.get('familyId')
    const hash = searchParams.get('hash')
    const lang = searchParams.get('lang') as GetAppUserMeRes['language']
    let navigate = useNavigate();

    console.log('familyId', familyId)

    const dispatch = useAppDispatch()
    return (
        <>
            <PageBox
                onLoadData={async () => {
                    await postAppAuthFamilyIdLogin({ mainFamilyId: familyId, hash })
                    await dispatch(getMetadata())
                    await dispatch(putAppUserMe({ language: lang || 'zh-tw' }))
                    const user = await dispatch(getAppUserMe())
                    await dispatch(setLocale(user.language))
                    navigate("/home");
                }}

            />
        </>
    )
}