import { MenuItem, Select, Stack, Typography } from "@mui/material";
import { color } from "../../theme";
import api, { FoodCardUserMember, GetAppUserMeRes, GetAreaRes, GetKioskInventoryRes } from "../../api";
import { ActionUserRes, getAppUserMe } from "../../actions/action-user";
import { getI18nText } from "../../public";



type HomeSelectType = {

    value?: string

    data: ((GetAreaRes & {
        kioskCount: number;
    }) | undefined)[]

    onValue(id: string): void
    user: ActionUserRes
}

export const HomeSelect = (props: HomeSelectType) => {
    const { value, data, onValue, user } = props
    console.log('HomeSelect', props)
    return (

        <Select
            displayEmpty={false}
            value={value}
            onChange={event => {
                onValue(event.target.value as string)
            }}
            style={{
                width: '100%',
                height: 35,
                color: color.secondary_1_0,
                backgroundColor:'white',
            }}
        >
            {data.map((area, index) => {
                return (
                    <MenuItem
                        value={area.id}
                        key={area.id}
                        style={{
                        }}
                    >
                        {`${getI18nText(area.name, user.language)} (${area.kioskCount})`}
                    </MenuItem>
                )
            })}
        </Select>
    )
}
