
import { GetAppUserMeRes, I18nAndDefaultInterface, I18nInterface } from "../api"


export const getI18nText = (data?: I18nAndDefaultInterface | I18nInterface, langs?: GetAppUserMeRes['language']) => {

    if (!data) {
        return '-'
    }

    const dataDefault = (data as I18nAndDefaultInterface)
    const dataBase = (data as I18nInterface)

    if (dataDefault.default) {
        return dataDefault[langs || 'default'] || dataDefault['default'] || '-'
    }

    return dataBase[langs || 'en'] || '-'

} 