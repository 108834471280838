import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { I18n, setLocale } from "react-redux-i18n";
import { color } from "../../theme";
import api, { FoodCardUserMember, GetAppUserMeRes, GetAreaRes, GetKioskInventoryRes, GetProductRes } from "../../api";
import { ActionConfigRes, setConfigData } from "../../actions";
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
export type HomeInventoryItemType = {
    kioskInvntory: GetKioskInventoryRes
    config: ActionConfigRes
    style?: React.CSSProperties
    onPressItem?(data: HomeInventoryItemType['kioskInvntory']): void
    onAddress?(data: HomeInventoryItemType['kioskInvntory']): void
}

export const HomeInventoryItem = (props: HomeInventoryItemType) => {
    const { kioskInvntory, config, } = props
    return (
        <div
            style={{
                backgroundColor: 'white',
                marginTop: 10,
                borderRadius: 10,
                borderColor: color.black_3,
                borderWidth: 0.3,
                borderStyle: 'solid',
                overflow: 'hidden',
            }}
        >
            <div
                style={{
                    padding: 3,
                    backgroundColor: kioskInvntory.kioskSaleState ? color.primary_0 : color.black_3,
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <span
                    style={{
                        fontWeight: '700',
                        fontSize: 14,
                        color: 'white',
                        transform: "scale(0.8)",
                    }}
                >
                    {kioskInvntory.kioskSaleState ? I18n.t('homeListItemKioskSaleTextSuccess') : I18n.t('homeListItemKioskSaleTextDanger')}
                </span>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: 5,
                    borderStyle: 'solid',
                    borderWidth: '0 0 1px 0',
                    borderColor: color.black_4,
                }}
            >
                <FmdGoodIcon
                    style={{
                        height: 40,
                        width: 40,
                        color: color.secondary_1_0,
                        marginRight: 10
                    }}
                />
                <div>
                    <Typography
                        color={'primary'}
                        variant="subtitle2"
                        component="div"
                        sx={{
                            fontWeight: '800',
                            color: color.secondary_1_0,
                        }}
                    >
                        {kioskInvntory?.name[config.language] || ''}
                    </Typography>
                    <Typography
                        color={'primary'}
                        variant="subtitle2"
                        component="div"
                        sx={{
                            fontWeight: '600',
                            fontSize: 14,
                            color: color.secondary_1_0,
                        }}
                    >
                        {kioskInvntory?.code?.substring(0, 6) || ''}
                    </Typography>
                </div>

            </div>

            <div
                onClick={() => props.onAddress && props.onAddress(kioskInvntory)}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: 5,
                    borderStyle: 'solid',
                    borderWidth: '0 0 1px 0',
                    borderColor: color.black_4,
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        height: 30,
                        width: 40,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: 10,
                    }}
                >
                    <HomeOutlinedIcon
                        style={{
                            height: 30,
                            width: 30,
                            color: color.secondary_1_0,
                        }}
                    />
                </div>

                <Typography
                    color={'primary'}
                    variant="subtitle2"
                    component="div"
                    sx={{
                        flex: 1,
                        fontWeight: '700',
                        fontSize: 14,
                        color: color.secondary_1_0,
                    }}
                >
                    {`${kioskInvntory?.address[config.language] || ''}`}
                </Typography>

            </div>

            {kioskInvntory?.openHour && kioskInvntory?.openHour[config.language] ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: 5,
                        borderStyle: 'solid',
                        borderWidth: '0 0 1px 0',
                        borderColor: color.black_4,
                        alignItems: 'center',
                    }}
                >

                    <div
                        style={{
                            height: 30,
                            width: 40,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: 10,
                        }}
                    >
                        <AccessTimeIcon
                            style={{
                                height: 30,
                                width: 30,
                                color: color.secondary_1_0,
                            }}
                        />
                    </div>

                    <Typography
                        color={'primary'}
                        variant="subtitle2"
                        component="span"
                        style={{
                            flex: 1,
                            fontWeight: '700',
                            fontSize: 14,
                            color: color.secondary_1_0,
                        }}
                    >
                        {kioskInvntory?.openHour[config.language] || ''}

                    </Typography>

                </div>
            ) : undefined
            }



            <div
                style={{
                    margin: 20,
                    borderRadius: 5,
                    backgroundColor: color.black_4,
                    overflow: 'hidden',
                    padding: 10,
                    display: 'felx',

                }}
            >
                {kioskInvntory.products.map((item, index) => {
                    return (
                        <Stack
                            direction="row"
                            justifyContent="space-around"
                            alignItems="center"
                            spacing={0}
                            key={index}
                            style={{
                                paddingBottom: 10,
                                paddingTop: 10,
                                borderStyle: 'solid',
                                borderWidth: `${index === 0 ? '0' : '1px'} 0 0 0`,
                                borderColor: color.black_4,
                            }}
                        >
                            <span
                                style={{
                                    fontWeight: '700',
                                    color: color.black_1,
                                    fontSize: 15,
                                    flex: 1,
                                }}
                            >
                                {`${item?.title[config.language]} `}
                            </span>
                            <span
                                style={{
                                    fontSize: 15,
                                    fontWeight: '700',
                                    color: color.black_1,
                                }}
                            >
                                {item.totalAmount}
                            </span>
                        </Stack>
                    )
                })}

            </div>
        </div>

    )
}


