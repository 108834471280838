import { Box, Card, CardActionArea, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { I18n, setLocale } from "react-redux-i18n";
import { color } from "../../theme";
import api, { FoodCardUserMember, GetAppUserMeRes, GetAreaRes, GetKioskInventoryRes } from "../../api";
import { ActionUserRes, getAppUserMe } from "../../actions/action-user";
import { getI18nText } from "../../public";
import moment from "moment";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { ActionConfigRes, setConfigData } from "../../actions";

type FoodCardItemType = {

    style?: React.CSSProperties
    foodCardUserMember: FoodCardUserMember
    onPress?(foodCardUserMember: FoodCardUserMember): void
    locale: GetAppUserMeRes['language']
}

const FoodCardItem = (props: FoodCardItemType) => {
    const quotas = props.foodCardUserMember.quotas
    const foodCardUserMember = props.foodCardUserMember

    const isAvailable = props.foodCardUserMember.isAvailable

    const dateList = [
        {
            title: I18n.t('FoodCardItem__latestUseAt'), value: props.foodCardUserMember.latestUseAt
                ? moment(props.foodCardUserMember.latestUseAt).format('YYYY-MM-DD HH:MM')
                : `-`
        },
        ...(foodCardUserMember.beginAt && foodCardUserMember.endAt
            ? [
                {
                    title: I18n.t('FoodCardItem__beginAt'),
                    value: moment(foodCardUserMember.beginAt).format('YYYY-MM-DD'),
                },
                {
                    title: I18n.t('FoodCardItem__endAt'),
                    value: moment(foodCardUserMember.endAt).format('YYYY-MM-DD'),
                },
            ]
            : []
        ),
    ]

    return (
        <Card
            sx={[props.style, {
                display: "flex",
                backgroundColor: color.secondary_1_0,
            }
            ]}
            elevation={3}
        >
            <CardActionArea
                onClick={() => props.onPress && props.onPress(props.foodCardUserMember)}
                disabled={!isAvailable}
                style={{
                    padding: 10,
                }}
            >
                <div
                    style={{
                        flexDirection: 'row',
                        display: "flex",
                    }}
                >
                    <QrCode2Icon
                        style={{
                            height: 50,
                            width: 50,
                            color: "white",
                            marginRight: 10
                        }}
                    />



                    <div
                        style={{
                            display: "flex",
                            flexDirection: 'column',
                        }}
                    >
                        <Typography
                            color={'primary'}
                            variant="subtitle1"
                            component="div"
                            sx={{
                                color: 'white',
                                textAlign: 'center',
                                fontWeight: '700',
                            }}
                        >{foodCardUserMember.foodCardCode}</Typography>
                        <div
                            style={{
                                marginTop: 10,
                            }}
                        >

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: 'column',
                                }}
                            >

                                {quotas.map((quota, index) => (
                                    <div
                                        key={index}
                                        style={{

                                        }}
                                    >

                                        <Typography
                                            color={'primary'}
                                            variant="body2"
                                            component="div"
                                            sx={{
                                                color: 'white',
                                                fontWeight: '600',
                                            }}
                                        >
                                            {getI18nText(quota.name, props.locale)}

                                        </Typography>

                                        <div
                                            style={{
                                                flexDirection: 'row',
                                                display: 'flex',
                                            }}
                                        >
                                            {
                                                [
                                                    ...typeof (quota.dayCount) === 'number' ? [
                                                        { title: I18n.t('FoodCardItem__dayInfo'), value: quota.dayCount },
                                                    ] : [],
                                                    ...typeof (quota.weekCount) === 'number' ? [
                                                        { title: I18n.t('FoodCardItem__weekInfo'), value: quota.weekCount },
                                                    ] : [],
                                                    ...typeof (quota.monthCount) === 'number' ? [
                                                        { title: I18n.t('FoodCardItem__monthInfo'), value: quota.monthCount },
                                                    ] : [],
                                                ].map((item, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                flexDirection: 'row',
                                                                marginLeft: index === 0 ? 0 : 5,
                                                                padding: 5,
                                                                borderRadius: 5 / 2,
                                                                backgroundColor: color.primary_0,
                                                                marginBottom: 5,
                                                                opacity: item.value > 0 ? 1 : 0.5,
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    color: 'white',
                                                                    fontWeight: '600',
                                                                    fontSize: 14,
                                                                }}
                                                            >{item.title}</span>
                                                            <span
                                                                style={{
                                                                    color: 'white',
                                                                    fontWeight: '600',
                                                                    fontSize: 14,
                                                                }}
                                                            >{item.value}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>


                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                {
                                    dateList.map((data, index) => {
                                        return (
                                            <span
                                                key={index}
                                                style={{
                                                    color: 'rgba(255,255,255,0.8)',
                                                    marginTop: 5,
                                                    fontSize: 14,
                                                }}
                                            >{`${data.title} ${data.value}`}</span>
                                        )
                                    })
                                }
                            </div>

                        </div>


                    </div>



                </div>
            </CardActionArea>





        </Card>
    )
}


type HomeHeaderType = {
    config: ActionConfigRes
    user: ActionUserRes
    onFoodCardItem?(props: FoodCardUserMember): void
}

export const HomeHeader = (props: HomeHeaderType) => {
    const { config, user, onFoodCardItem } = props
    return (
        <div
            style={{
                overflowX: 'scroll',
                flexDirection: 'row',
                display: 'flex',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexShrink: 0,
                }}
            >
                {user.foodCardUserMembers.map((item, index) => {
                    return (
                        <div
                            style={{
                                marginLeft: index === 0 ? 0 : 10,
                            }}
                            key={index}
                        >
                            <FoodCardItem
                                foodCardUserMember={item}
                                locale={config.language}
                                onPress={(props) => {
                                    onFoodCardItem && onFoodCardItem(props)
                                }}
                            />
                        </div>

                    )
                })}
            </div>


        </div>
    )
}

